<template>
   <figure :class="`gallery--fit gallery--video-gallery`">
    <GridLines :columns="[1,2,10,11,12]" />
    <h3 v-if="item.title" class="wrapper">{{ item.title }}</h3>

    <div class="wrapper">
      <div class="inner-wrapper">
        <div class="video-gallery" :class="{'video-gallery': item.gallery.length < 6}">
          <figure v-for="(video, index) in item.gallery" :key="index" class="video-gallery--item" @mouseover="loopVideo(index)" @mouseout="stopVideo(index)" @click="open($event, video.youtube, index)">
            <a :href="video.youtube">
              <img :src="require(`../assets/case-study/${video.thumbnail}`)" :alt="`${video.title}`">
            </a>
            <video class="preview" v-if="video.preview" loop ref="video" muted>
              <source :src="require(`../assets/case-study/${video.preview}`)" type="video/mp4">
            </video>
          </figure>
        </div>
      </div>
    </div>
  </figure>
</template>

<script>
import GridLines from '@/components/GridLines.vue';
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
  name: 'GalleryVideoGallery',
  components: {
    GridLines
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    }
  },
  methods: {
    parseYouTubeURL(url) {
      const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11) ? `https://www.youtube.com/embed/${match[2]}` : false;
    },
    loopVideo(i) {
      clearTimeout(this.timeout);
      if (!this.$refs.video[i]) return;
      this.$refs.video[i].currentTime = 0;
      this.$refs.video[i].play();
    },
    stopVideo(i) {
      const vm = this
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        if (!vm.$refs.video[i]) return;
        vm.$refs.video[i].currentTime = 0;
        vm.$refs.video[i].pause();
      }, 300)
    },
    open(e, url, i) {
      e.preventDefault();
      this.stopVideo(i);
      Fancybox.show([
        {
          src: this.parseYouTubeURL(url),
          type: 'iframe'
        }
      ]);
    }
  }
}

</script>
