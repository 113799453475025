<template>
   <figure :class="`gallery--fit gallery--video-gallery`">
    <GridLines :columns="[1,2,10,11,12]" />
    <h3 v-if="item.title" class="wrapper">{{ item.title }}</h3>

    <div class="wrapper">
      <div class="inner-wrapper">
        <div class="gallery-grid" :class="{'gallery-grid__small': item.gallery.length < 6}">
          <figure v-for="(image, index) in item.gallery" :key="index" class="gallery-grid--item">
            <img :src="require(`../assets/case-study/${image.image}`)" :alt="`${image.alt}`">
          </figure>
        </div>
      </div>
    </div>
  </figure>
</template>

<script>
import GridLines from '@/components/GridLines.vue';

export default {
  name: 'GalleryGrid',
  components: {
    GridLines
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}

</script>
