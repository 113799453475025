<template>
  <figure class="gallery--social" :style="{backgroundColor: item.bgcolor}" :data-brightness="evaluateBrightness(item.bgcolor)">
    <div class="wrapper gallery--social__frame" >
      <div class="gallery--social__video">
        <video :poster="require(`../assets/case-study/${item.poster}`)" :noloop="item.settings && item.settings.includes('noloop')" :loop="!item.settings || !item.settings.includes('noloop')" muted ref="v">
          <source :src="require(`../assets/case-study/${item.video}`)" type="video/mp4">
          <img :src="require(`../assets/case-study/${item.poster}`)" :alt="`${item.alt}`">
        </video>
      </div>
      <div class="gallery--social__phone">
        <img :src="require(`../assets/case-study/${item.static}`)" :alt="`${item.alt}`">
      </div>
    </div>
  </figure>
</template>

<script>
export default {
  name: 'GallerySocial',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    evaluateBrightness(hex) {
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness >= 190 ? 'light' : 'dark';
    },
    handleScroll() {
      if (!this.$refs.v) return;
      const containerOffset = this.$refs.v.getBoundingClientRect().top;
      const threeQuartersViewport = (window.innerHeight / 4) * 3;

      // this reveals the content when it's in the viewport
      // (half the viewport height is the trigger point)
      if (0 >= containerOffset - threeQuartersViewport) {
        setTimeout(() => {
          this.$refs.v.play();
        }, 500);
      }
    }
  },
  mounted () {
    setTimeout(() => {
      document.addEventListener('scroll', this._debounce(this.handleScroll, 50));
    }, 1000);

    if (!this.$route.meta.async) {
      // console.log('render done')
      document.dispatchEvent(new Event('render-event'))
    }
  }
}

</script>
