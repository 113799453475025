<template>
  <div>
    <PageBlock :block-title="blockTitle" :block-columns="blockColumns" :grid="[1,5,8,11,12]" data-brightness="light" backTo="/#our-work">
      <slot></slot>
      <template v-slot:secondary>
        <div class="homepage-slider work-slider" v-if="currentSlide">
          <div class="homepage-slider--info-column work-slider--about anim">
            <p class="column-label">
              {{ blockColumns[1] }}
            </p>

            <h3>
              {{ csl(currentSlide.service) }}
            </h3>

            <router-link :to="{ name: 'our-work', params: { slug: currentSlide.slug } }" role="button" :aria-label="`Open ${currentSlide.title} Case Study`">
              <button class="button button--outline" tabindex="-1">
                {{ currentSlide.title }}
              </button>
            </router-link>
          </div>
          <div class="homepage-slider--images work-slider--images">
            <div class="homepage-slider--images__container">
              <div class="homepage-slider--images__container__inner work-slider--images__container__inner" :style="{ transform: 'translateY(-' + ((sliderIndex / slider.length) * 100) + '%)' }" role="group">
                <div class="homepage-slider--images__container__inner__slide work-slider--images__container__inner__slide" v-for="(slide, index) in slider" :key="index" :class="{current: index == sliderIndex}" :style="{background: slide.accent}" :aria-hidden="index != sliderIndex" role="region" :aria-label="slide.title">
                  <router-link :to="{ name: 'our-work', params: { slug: slide.slug } }" :tabindex="(index != sliderIndex) ? '-1' : '0'">
                    <img :src="require(`../assets/work/main-${slide.slug}.jpg`)" :alt="`${slide.title} : ${csl(slide.service)}`" />
                  </router-link>
                </div>
              </div>
            </div>
            <button class="homepage-slider--controls work-slider--controls homepage-slider--controls__up work-slider--controls__up" @click="sliderIndexUpdate(sliderIndex - 1)" :disabled="sliderIndex == 0" aria-label="Previous Slide" :aria-disabled="sliderIndex == 0">
              <span class="icon icon--arrow-up"></span>
            </button>
            <button class="homepage-slider--controls work-slider--controls homepage-slider--controls__down work-slider--controls__down" @click="sliderIndexUpdate(sliderIndex + 1)" :disabled="sliderIndex == slider.length - 1" aria-label="Next Slide" :aria-disabled="sliderIndex == slider.length - 1">
              <span class="icon icon--arrow-down"></span>
            </button>
          </div>
        </div>
      </template>
    </PageBlock>
    <section class="work-grid" data-brightness="light">
      <CaseStudyThumbnail class="work-grid--item" v-for="(item, index) in grid" :key="index" :item="item" />
    </section>
  </div>
</template>

<script>
import PageBlock from '@/components/PageBlock.vue'
import work from '@/assets/work.yaml';
import CaseStudyThumbnail from '@/components/CaseStudyThumbnail.vue';

export default {
  name: 'WorkBlock',
  components: {
    PageBlock,
    CaseStudyThumbnail
  },
  props: {
    blockTitle: {
      type: String,
      default: 'Work'
    },
    blockColumns: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.sliderIndex = this.$store.getters.myWorkSliderPos
  },
  computed: {
    currentSlide () {
      return this.slider[this.sliderIndex]
    },
    slider () {
      return this._sortBy(
        this._filter(
          this._map(work, (w, slug) => {
            w.slug = slug;
            return w;
          }),
          { visibility: { slider: true } }
        )
      , 'position')
    },
    grid () {
      return this._sortBy(
        this._filter(
          this._map(work, (w, slug) => {
            w.slug = slug;
            return w;
          }),
          { visibility: { grid: true } }
        )
      , 'position')
    }
  },
  data () {
    return {
      sliderIndex: 0,
      work: work || {}
    }
  },
  methods: {
    sliderIndexUpdate (index) {
      this.sliderIndex = index
      this.$store.commit('myWorkSliderPos', index)
    },
    csl (value) {
      if (!value) return ''
      if (typeof value === 'string') return value
      let list = value.join(', ')
      const lastCommaIndex = list.lastIndexOf(', ');
      if (lastCommaIndex !== -1) {
        list = list.substring(0, lastCommaIndex) + ' and ' + list.substring(lastCommaIndex + 2);
      }
      return list;
    }
  }
}
</script>
